@import "/src/_styleguide/variables.scss";

.import-modal {
  .ant-modal-body {
    padding: 0 !important;
    min-height: 200px;
  }
  .upload-doc {
    .ant-upload-wrapper {
      display: block;
      height: 200px;
    }
  }
  .barn-modal-close {
    display: flex;
    height: 32px;
    width: 32px;
    justify-content: center;
    align-items: center;
    border-radius: 42px;
    border: 1px solid #d9d9d9;
    background: #fff;
    box-shadow: 1px 1px 0px 0px #f3f3f3;
    &:hover {
      border-color: #000;
    }
  }
}
