@import "/src/_styleguide/variables.scss";

.manage-custom-field-modal {
  .ant-modal-body {
    padding: 0 !important;
  }
  .barn-modal-body {
    display: flex;
    padding: 24px 28px 16px 28px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    margin: 0 !important;
    min-height: 200px;
    > div {
      align-self: stretch;
    }
  }
  .mcf-divider {
    width: 100%;
    height: 1px;
    background-color: #efefef;
    margin: 12px 0;
  }
  .mcf-dropdown-options {
    position: relative;
    > .ant-flex {
      width: 100%;
      margin-top: 12px;
      > div {
        flex: 1;
      }
    }
    button {
      position: absolute;
      right: 0;
      top: -6px;
      display: flex;
      align-items: center;
      svg {
        width: 14px;
        height: 14px;
        margin-right: 4px;
        stroke-width: 2.5;
        position: relative;
        top: -1px;
      }
    }
  }
  .barn-modal-actions {
    display: flex;
    padding: 16px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    margin: 0 !important;
    button {
      font-weight: 500 !important;
      font-family: $font-title;
      font-size: 16px;
      font-style: normal;
      line-height: normal;
    }
  }
}
.mcf-select-dropdown .mcf-select,
.manage-custom-field-modal .mcf-select {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  svg {
    width: 16px;
    height: 16px;
    position: relative;
    top: 3px;
  }
  .mcf-select-label {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
    h5 {
      margin: 0;
      font-family: $font-main;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 21px; /* 150% */
      letter-spacing: -0.28px;
    }
    p {
      margin: 0;
      color: #757575;
      font-family: $font-main;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 150% */
      letter-spacing: -0.24px;
    }
  }
}
.manage-custom-field-modal .mcf-select {
  .mcf-select-label p {
    display: none;
  }
}
