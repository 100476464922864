@import "/src/_styleguide/variables.scss";

.import-modal {
  .mp-options {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    padding: 24px;
    .mp-option {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      padding: 16px;
      flex: calc(50% - 44px);
      max-width: calc(50% - 44px);
      border-radius: 12px;
      border: 1px solid #ececec;
      background: #fff;
      height: 100px;
      overflow: hidden;
      justify-content: space-between;
      cursor: pointer;
      position: relative;
      svg {
        display: none;
      }
      p {
        margin: 0 !important;
        color: #000;
        font-family: $font-title;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
      }

      &:hover,
      &.selected {
        border: 1px solid #000;
        background: #fff;
        box-shadow: 0px 0px 0px 3px #ececec;
        svg {
          display: block;
          position: absolute;
          top: 16px;
          right: 16px;
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}
