@import "/src/_styleguide/variables.scss";

.import-mapping {
  .wb-headers {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ededed;
    background: #fafaf9;
    > div {
      width: 33%;
      padding: 12px 16px;
      color: #000;
      font-family: $font-title;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
  .ma-info {
    color: rgba(0, 0, 0, 0.3);
    font-family: $font-title;
    font-size: 14px;
    font-style: italic;
  }
  .wb-actions {
    display: flex;
    align-items: center;
    border-top: 1px solid #ededed;
    padding: 16px 24px;
    justify-content: space-between;
    button {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  .wb-work {
    max-height: 400px;
    overflow-y: scroll;
    .wb-map {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #ededed;
      background: #fff;
      > div {
        width: 33%;
        max-width: 33%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 12px 16px;
        color: #000;
        font-family: $font-title;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        .ant-select-selection-placeholder,
        .ant-select-selection-item {
          font-size: 14px !important;
        }
        b {
          font-weight: 500;
        }
      }
    }
  }
}

.ma-option-dropdown .ma-option,
.import-mapping .ma-option {
  display: flex;
  align-items: center;
  gap: 8px;
  > div {
    display: flex;
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    justify-content: center;
    align-items: center;
    border-radius: 31px;
    border: 0.5px solid #d9d9d9;
    background: #d9d9d9;
    svg {
      width: 14px;
      height: 14px;
      stroke-width: 2.5 !important;
    }
    &.ma-icon-ignore,
    &.ma-icon-new,
    &.ma-icon-custom {
      background: #d9d9d9;
      svg {
        color: #000;
      }
    }
    &.ma-icon-field {
      background: #0f766e;
      border-color: #0f766e;
      svg {
        color: #fff;
      }
    }

    &.ma-icon-unmatched {
      background: transparent;
      border-color: transparent;
      svg {
        color: #f97316;
        width: 20px;
        height: 20px;
        stroke-width: 2 !important;
      }
    }
  }
}

.ma-field-mapping {
  .ant-select-item.ant-select-item-option-disabled
    .ant-select-item-option-content {
    color: rgba(0, 0, 0, 0.3) !important;
  }
}
