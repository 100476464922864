@import "/src/_styleguide/variables.scss";

.import-modal {
  .upload-doc {
    padding: 16px 24px 24px 24px;
    .ant-upload-wrapper .ant-upload-drag:not(.ant-upload-disabled):hover,
    .ant-upload-wrapper .ant-upload-drag-hover:not(.ant-upload-disabled) {
      border-color: #000;
    }
    .ud-drag-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0;
      .ud-drag-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 48px;
        height: 48px;
        border-radius: 24px;
        border: 0.5px solid #d9d9d9;
        background: #fff;
        box-shadow: 0px 3.429px 10.286px 0px rgba(40, 43, 48, 0.1);
        margin-bottom: 16px;
      }
      h4 {
        margin: 0 0 8px 0;
        color: #000;
        font-family: $font-title;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      p {
        margin: 0;
        color: #757575;
        text-align: center;
        font-family: $font-title;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
      }
    }
  }
}
